import React, { useState, useEffect } from 'react';
import AppointmentCalendar from '../components/appointmentComponents/AppointmentCalendar';
import BookingModal from '../components/appointmentComponents/BookingModal';
import appointmentAPI from '../API/appointmentAPI';
import { DateSelectArg } from '@fullcalendar/core';
import { ITimeSlot } from '../interfaces/ITimeSlot';
import { useParams } from 'react-router-dom';

function Appointment() {
  const { serviceId, offerId } = useParams();
  const serviceIdnum = Number(serviceId);
  const [events, setEvents] = useState<ITimeSlot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<DateSelectArg | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchEvents = async () => {
    try {
      const appointments = await appointmentAPI.getAllAppointments(serviceIdnum);
      const eventsData = appointments.map((appointment: any) => ({
        title: 'Occupied',
        start: appointment.startDate,
        end: appointment.endDate,
        color: '#ff6961', // Occupied slot color
      }));
      setEvents(eventsData);
    } catch (error) {
      console.error('Failed to fetch appointments', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const now = new Date();
    if (selectInfo.start < now) {
      alert('Cannot select past time slots.');
      selectInfo.view.calendar.unselect(); // Clear selection
      return;
    }

    // Check for overlap with existing events
    const overlappingEvent = events.find(
      (event) =>
        (selectInfo.start >= new Date(event.start) && selectInfo.start < new Date(event.end)) ||
        (selectInfo.end > new Date(event.start) && selectInfo.end <= new Date(event.end))
    );

    if (overlappingEvent) {
      alert('This time slot is already occupied.');
      selectInfo.view.calendar.unselect(); // Clear selection
      return;
    }

    setSelectedSlot(selectInfo);
    setIsModalOpen(true);
  };

  const handleEventClick = (clickInfo: any) => {
    alert('This time slot is occupied.');
  };

  const handleBookingSubmit = async (bookingData: any) => {
    if (selectedSlot) {
      try {
        const newAppointment = {
          startDate: selectedSlot.startStr,
          endDate: selectedSlot.endStr,
          ...bookingData,
        };
        await appointmentAPI.createAppointment(newAppointment);
        setIsModalOpen(false);
        fetchEvents(); // Refresh events to include the new appointment
      } catch (error) {
        console.error('Failed to book appointment', error);
        alert('An error occurred while booking your appointment. Please try again.');
      }
    }
  };

  return (
    <div>
      <h1>Book an Appointment</h1>
      <AppointmentCalendar
        events={events}
        onDateSelect={handleDateSelect}
        onEventClick={handleEventClick}
        initialDate={new Date()}
      />
      {isModalOpen && selectedSlot && (
        <BookingModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedSlot={selectedSlot}
          onSubmit={handleBookingSubmit}
        />
      )}
    </div>
  );
}

export default Appointment;
