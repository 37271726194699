import React from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ITimeSlot } from '../../interfaces/ITimeSlot';

interface AppointmentCalendarProps {
  events: ITimeSlot[];
  onDateSelect: (selectInfo: DateSelectArg) => void;
  onEventClick: (clickInfo: EventClickArg) => void;
  initialDate: Date;
}

const AppointmentCalendar: React.FC<AppointmentCalendarProps> = ({
  events,
  onDateSelect,
  onEventClick,
  initialDate,
}) => {
  return (
    <FullCalendar
      plugins={[timeGridPlugin, interactionPlugin]}
      initialView="timeGridWeek"
      initialDate={initialDate}
      events={events}
      selectable={true}
      select={onDateSelect}
      eventClick={onEventClick}
      allDaySlot={false}
      slotDuration="00:30:00"
      slotLabelInterval="01:00"
      height="auto"
      weekends={true}
      selectLongPressDelay={60}       // Immediate selection
      selectMinDistance={5}          // Prevent accidental selections
    />
  );
};

export default AppointmentCalendar;
